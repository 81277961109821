import { useEffect, useState, useCallback } from "react";
import { isMobile } from "react-device-detect";

const MOBILE_BREAKPOINT: number = 768;

const useIsMobile = () => {
  const checkIsMobile = () => window.innerWidth < MOBILE_BREAKPOINT;
  const [isMobileBk, setIsMobileBk] = useState(checkIsMobile() || isMobile);

  const handleWindowSizeChange = useCallback(() => {
    setTimeout(() => {
      setIsMobileBk(checkIsMobile() || isMobile);
    }, 200);
  }, []); 

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [handleWindowSizeChange]); 

  return isMobileBk;
};

export default useIsMobile;
