import { useEffect } from "react";
import { useLocation } from "react-router-dom";

enum EHash {
  TECHNOLOGY = "heroButtonSection",
  BENEFITS = "benefitsFold",
  CARD = "optionsFold",
  PROCESS = "worksFold",
  WALLET = "appFold",
  FAQ = "faqFold",
}

const hashMap: any = {
  [EHash.TECHNOLOGY]: ["technology", "tehnologie"],
  [EHash.BENEFITS]: ["members-benefits", "beneficii-de-membru"],
  [EHash.CARD]: ["members-card", "cardul-de-membru"],
  [EHash.PROCESS]: ["how-it-works", "cum-functioneaza"],
  [EHash.WALLET]: ["wallet-app"],
  [EHash.FAQ]: ["faq", "intrebari-frecvente"],
};

export function useHashScroll() {
  const { hash } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === "") {
      window.scrollTo(0, 0);
    }
    // else scroll to section
    else {
      const hashValue = hash.replace("#", "");
      const id = Object.keys(hashMap).find((key: any) =>
        hashMap[key].includes(hashValue)
      );

      if (id) {
        setTimeout(() => {
          const element = document.getElementById(id);
          if (element) {
            element.scrollIntoView();
          }
        }, 150);
      }
    }
  }, [hash]);
}
