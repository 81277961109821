export enum Mode {
  light = "light",
  dark = "dark",
}

export enum LOCALES {
  ENGLISH = "en-US",
  SPANISH = "es-ES",
}

export interface ILocales {
  [key: string]: {
    icon: string;
    label: string;
  };
}

export const locales: ILocales = {
  [LOCALES.ENGLISH]: {
    icon: "🇺🇸",
    label: "English",
  },
  [LOCALES.SPANISH]: {
    icon: "🇪🇸",
    label: "Español",
  },
};

export interface AxiosResponseError {
  response: {
    data: {
      error: string;
    };
  };
}
