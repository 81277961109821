import { Grid } from "@mui/material";
import {
  StyledCard,
  StyledOverlay,
  StyledContent,
  StyledTitle,
  StyledText,
} from "../../../../components/StyledCard";
import useIsMobile from "../../../../hooks/useIsMobile";

const BenefitsGrid = () => {
  const isMobile = useIsMobile();

  return (
    <Grid container spacing={2} className="benefits-grid">
      <Grid item xs={12} md={4}>
        <StyledCard
          rounded="bottomRight"
          bgimageurl="/media/el-jardin-benefits-1-opt.png"
        >
          {/* <StyledOverlay /> */}
          <StyledContent
            text="light"
            size="sm"
            width={isMobile ? "85% !important" : "initial"}
          >
            <StyledTitle>Access to our private locations</StyledTitle>
            <StyledText>
              Meet and interact with other members at one of our association’s
              locations. It's the perfect spot to connect with others who share
              your interests, whether it's over a casual coffee in our relaxed
              lounge area or during a lively private event. Join our community
              and find your place among friends and fellow enthusiasts.
            </StyledText>
          </StyledContent>
        </StyledCard>
      </Grid>
      <Grid item xs={12} md={8}>
        <StyledCard rounded="topLeft" bgcolor="#4ADE80">
          <StyledContent
            content="bottom"
            size="lg"
            width={isMobile ? "85% !important" : "initial"}
          >
            <StyledTitle>Exclusive educational content</StyledTitle>
            <StyledText>
              Expand your knowledge with our exclusive educational content. This
              includes expert-led seminars, informative articles, and
              interactive workshops covering a range of topics from wellness to
              industry insights, ensuring members are always learning and
              growing.
            </StyledText>
          </StyledContent>
        </StyledCard>
      </Grid>
      <Grid item xs={12} md={8}>
        <StyledCard
          rounded="bottomRight"
          bgimageurl="/media/el-jardin-benefits-2-opt.png"
        >
          {/* <StyledOverlay /> */}
          <StyledContent
            text="light"
            size="lg"
            width={isMobile ? "85% !important" : "initial"}
          >
            <StyledTitle>Recreational and wellbeing activities</StyledTitle>
            <StyledText>
              Dive into a variety of recreational activities designed to enrich
              your leisure time. Offering various creative sessions and
              workshops, these activities are curated to provide relaxation,
              skill development, and a fun way to connect with fellow members.
            </StyledText>
          </StyledContent>
        </StyledCard>
      </Grid>
      <Grid item xs={12} md={4}>
        <StyledCard
          rounded="topLeft"
          bgimageurl="/media/el-jardin-benefits-3-opt.jpeg"
        >
          <StyledOverlay />
          <StyledContent
            content="bottom"
            text="light"
            size="sm"
            width={isMobile ? "85% !important" : "initial"}
          >
            <StyledTitle>Discounts with partner businesses</StyledTitle>
            <StyledText>
              Members benefit from exclusive discounts and offers from our wide
              range of partner businesses. These partnerships provide access to
              premium products and services, enhancing the overall value of your
              membership.
            </StyledText>
          </StyledContent>
        </StyledCard>
      </Grid>
      <Grid item xs={12} md={4}>
        <StyledCard rounded="bottomRight" bgcolor="#4ADE80">
          <StyledContent
            size="sm"
            width={isMobile ? "85% !important" : "initial"}
          >
            <StyledTitle>Private networking opportunities</StyledTitle>
            <StyledText>
              Leverage the power of community with our private networking
              opportunities. Engage with professionals and enthusiasts from
              diverse backgrounds, fostering meaningful connections and
              collaborations that extend beyond the assocation’s walls.
            </StyledText>
          </StyledContent>
        </StyledCard>
      </Grid>
      <Grid item xs={12} md={8}>
        <StyledCard
          rounded="topLeft"
          bgimageurl="/media/el-jardin-benefits-4-opt.png"
        >
          {/* <StyledOverlay /> */}
          <StyledContent
            content="bottom"
            text="light"
            size="lg"
            width={isMobile ? "85% !important" : "initial"}
          >
            <StyledTitle>Exclusive member events</StyledTitle>
            <StyledText>
              Members enjoy access to exclusive events, ranging from social
              gatherings to cultural celebrations. These events offer a unique
              opportunity to connect with like-minded individuals, enjoy special
              performances, and experience the vibrant community spirit of our
              association.
            </StyledText>
          </StyledContent>
        </StyledCard>
      </Grid>
    </Grid>
  );
};
export default BenefitsGrid;
