import { Routes, Route, Navigate } from "react-router-dom";
import en from "../lang/en.json";
import es from "../lang/es.json";
import Layout from "../layouts";
import HomePage from "./home";
import StatutePage from "./support/statute";
import TermsPage from "./support/terms";
import PrivacyPage from "./support/policy";

const AppRouter = () => {
  return (
    <Routes>
      <Route path={es.links.home} element={<Layout />}>
        <Route index element={<HomePage />} />

        <Route path="*" element={<Navigate to="/" />} />
      </Route>

      <Route path="/" element={<Layout />}>
        <Route index element={<HomePage />} />

        <Route path={en.links.entityStatute} element={<StatutePage />} />
        <Route path={en.links.terms} element={<TermsPage />} />
        <Route path={en.links.privacy} element={<PrivacyPage />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Route>
    </Routes>
  );
};

export default AppRouter;
