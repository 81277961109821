import { Box, Container } from "@mui/material";
import "./index.css";
import walletMockupWebp from "../../../../assets/section-wallet.webp";
import walletMockupJpg from "../../../../assets/section-wallet.png";
import { FormattedMessage } from "react-intl";
import googleApp from "../../../../assets/icons/google-theme-dark.svg";
import appleApp from "../../../../assets/icons/apple-theme-dark.svg";
import PictureElement from "../../../../components/PictureElement";
import useIsMobile from "../../../../hooks/useIsMobile";

const AppFold = () => {
  const isMobile = useIsMobile();

  return (
    <div id="appFold" className="app-fold">
      <Container
        className="home-wrapper"
        maxWidth="lg"
        disableGutters={true}
        sx={{ px: 1 }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          flexDirection={isMobile ? "column" : "row"}
          gap={isMobile ? "0" : "215px"}
        >
          <div className="fold-title-section">
            <div className="fold-title-text">
              <span className="fold-caption">Mobile app</span>

              <h2 className="fold-title">
                <FormattedMessage id="discover.s6.title" />
              </h2>
              <p className="fold-paragraph">
                <FormattedMessage id="discover.s6.text" />
              </p>
            </div>

            <div className="store-link-wrapper">
              {
                <h4 className="fold-subtitle">
                  <FormattedMessage id="discover.s6.appInfo1.title" />:
                </h4>
              }
              <a href="#" target="_blank" className="store-link">
                <img
                  src={appleApp}
                  loading="lazy"
                  alt="AppleStore"
                  className="image store-link-image"
                />
              </a>
              <a href="#" target="_blank" className="store-link">
                <img
                  src={googleApp}
                  loading="lazy"
                  alt="GooglePlay"
                  className="image store-link-image"
                />
              </a>
            </div>
          </div>
          <div className="app-wrapper">
            <div className="app-mockup">
              <PictureElement webp={walletMockupWebp} jpg={walletMockupJpg} />
              <div className="app-video-container">
                <video
                  className="app-bg-video"
                  autoPlay={true}
                  muted={true}
                  playsInline={true}
                  loop={true}
                >
                  <source
                    data-src="/media/el-jardin-app-vid.mp4"
                    type="video/mp4"
                    src="/media/el-jardin-app-vid.mp4"
                  ></source>
                </video>
              </div>
            </div>
          </div>
        </Box>
      </Container>
    </div>
  );
};

export default AppFold;
