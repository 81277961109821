import { LOCALES, Mode } from "../../types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const APPLICATION_FEATURE_KEY = "application";

export interface IApplicationState {
  isLoading: boolean;
  userPrefMode: Mode | null;
  mode: Mode;
  language: LOCALES;
  location?: string;
}

export const initialState: IApplicationState = {
  isLoading: false,
  mode: Mode.light,
  userPrefMode: null,
  language: LOCALES.ENGLISH,
  location: undefined,
};

export const applicationSlice = createSlice({
  name: APPLICATION_FEATURE_KEY,
  initialState: initialState,
  reducers: {
    setPageLoading: (
      state: IApplicationState,
      action: PayloadAction<boolean>
    ) => {
      state.isLoading = action.payload;
    },

    setMode: (state: IApplicationState, action: PayloadAction<Mode>) => {
      state.mode = action.payload;
    },

    setLanguage: (state: IApplicationState, action: PayloadAction<LOCALES>) => {
      state.language = action.payload;
    },

    setUserPrefMode: (
      state: IApplicationState,
      action: PayloadAction<Mode | null>
    ) => {
      state.userPrefMode = action.payload;
    },
  },
});

export const locale = (state: IApplicationState): LOCALES => state.language;

export const { setPageLoading, setMode, setLanguage, setUserPrefMode } =
  applicationSlice.actions;

export default applicationSlice.reducer;
