import {
  useState,
  createContext,
  useContext,
  useLayoutEffect,
  useCallback,
} from "react";
import { IntlProvider } from "react-intl";
import { LOCALES, messages } from "./";
import { useLocation } from "react-router-dom";

const LangContext = createContext({
  language: LOCALES.ENGLISH,
  setLanguage: (language: LOCALES) => {},
  changeLanguagePath: (language: LOCALES): string => {
    return "/";
  },
  localizedPath: (path: string): string => {
    return path;
  },
});

function getLocaleFromString(localeString: string | null): LOCALES {
  switch (localeString) {
    case LOCALES.ENGLISH:
      return LOCALES.ENGLISH;
    case LOCALES.SPANISH:
      return LOCALES.SPANISH;
    default:
      return LOCALES.ENGLISH; 
  }
}

function LangProvider({ children }: { children: React.ReactNode }) {
  const location = useLocation().pathname.split("/");
  const [language, _setLanguage] = useState<LOCALES>(
    getLocaleFromString(location[1])
  );
  const setLanguage = (language: LOCALES) => {
    _setLanguage(language);
  };

  window.document.documentElement.setAttribute("lang", language);
  document.body.classList.add("lang-" + language);

  useLayoutEffect(() => {
    const lang = getLocaleFromString(location[1]);
    if (lang) {
      _setLanguage(lang);
    }
  }, [location]);

  const changeLanguagePath = (language: LOCALES) => {
    const allowedLanguages = [LOCALES.ENGLISH, LOCALES.SPANISH];
    if (language === LOCALES.ENGLISH) {
      if (!location.includes(LOCALES.SPANISH)) return location.join("/");

      return `/${location.slice(2).join("/")}`;
    } else if (allowedLanguages.includes(language)) {
      if (location.includes(language)) return location.join("/");
      return `/${language}${location.join("/")}`;
    }
    return `/${language}`;
  };

  const localizedPath = useCallback(
    (path: string) => {
      if (language === LOCALES.ENGLISH) {
        return path;
      }
      return `/${language}${path}`;
    },
    [language]
  );

  return (
    <LangContext.Provider
      value={{ language, setLanguage, changeLanguagePath, localizedPath }}
    >
      <IntlProvider locale={language} messages={messages[language]}>
        {children}
      </IntlProvider>
    </LangContext.Provider>
  );
}

export default LangProvider;

export const useLang = () => {
  if (!useContext)
    throw new Error("useLang must be used within a LangProvider");
  return useContext(LangContext);
};
