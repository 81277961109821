import styled from "@emotion/styled";
import { Stack, Divider } from "@mui/material";
import { useCallback } from "react";
import { LOCALES } from "../../lang";
import { useLang } from "../../lang/Provider";
import { Link } from "react-router-dom";

const StyledLanguageLink = styled(Link)`
  color: #a3a3a3;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  text-decoration: none;
  
  pointer-events: none !important;
`;

const activeLinkStyle = {
  color: "#16A34A",
  fontWeight: 600,
};

export const LanguageSwitch = ({
  setOpenMobileMenu,
}: {
  setOpenMobileMenu?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { language, changeLanguagePath } = useLang();

  const handleLangSelected = useCallback(() => {
    const selectedLang =
      language === LOCALES.ENGLISH ? LOCALES.SPANISH : LOCALES.ENGLISH;
    localStorage.setItem("langSelected", "true");
    localStorage.setItem("countryCode", selectedLang);
    setOpenMobileMenu?.(false);
  }, [language, setOpenMobileMenu]);

  return (
    <Stack
      direction="row"
      divider={
        <Divider
          orientation="vertical"
          flexItem
          sx={{ backgroundColor: "#a3a3a3", my: "24px !important" }}
        />
      }
      spacing={"8px"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <StyledLanguageLink
        to={changeLanguagePath(LOCALES.ENGLISH)}
        onClick={handleLangSelected}
        style={language === LOCALES.ENGLISH ? activeLinkStyle : {}}
      >
        EN
      </StyledLanguageLink>
      <StyledLanguageLink
        to={changeLanguagePath(LOCALES.SPANISH)}
        onClick={handleLangSelected}
        style={language === LOCALES.SPANISH ? activeLinkStyle : {}}
      >
        ES
      </StyledLanguageLink>
    </Stack>
  );
};
