import { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { LOCALES } from "../lang";
import { useLang } from "../lang/Provider";
import Header from "../components/Header";

const Layout = () => {
  const navigate = useNavigate();
  const { language, changeLanguagePath } = useLang();

  const [countryCode, setCountryCode] = useState<string>("");

  useEffect(() => {
    const fetchCountryCode = async () => {
      try {
        const response = await fetch(
          `https://pro.ip-api.com/json?key=${process.env.REACT_PRO_IP_API_KEY}`
        );
        const data = await response.json();
        const lang = data.country === "ES" ? LOCALES.SPANISH : LOCALES.ENGLISH;
        localStorage.setItem("countryCode", lang);
        setCountryCode(lang);
      } catch (error) {
        console.log(error);
      }
    };

    if (!localStorage.getItem("countryCode")) fetchCountryCode();
  }, [language]);

  useEffect(() => {
    if (
      localStorage.getItem("countryCode") &&
      !localStorage.getItem("langSelected")
    ) {
      localStorage.setItem("langSelected", "true");
      navigate(
        changeLanguagePath(
          localStorage.getItem("countryCode") === LOCALES.SPANISH
            ? LOCALES.SPANISH
            : LOCALES.ENGLISH
        )
      );
    }
  }, [countryCode, changeLanguagePath, navigate]);

  return (
    <>
      <Header />
      <main className={"main"}>
        <Outlet />
      </main>
    </>
  );
};

export default Layout;
