let config = {
  ADOBE_VIEWER_ID: process.env.REACT_ADOBE_VIEWER_ID,

  PAY_PRICE_ESSENTIAL: 25,
  PAY_PRICE_ELITE: 100,
  PAY_CARD_FEE: 1.5,
  PAY_BANK_FEE: 0,
  PAY_USDC_FEE: 1.5,

  CRISP_ID: process.env.REACT_APP_CRISP_ID,

  MIN_PARTICIPATION: 500,
  MAX_PARTICIPATION: 10000,
};

export default config;
