import "./index.css";

import { Helmet } from "react-helmet";
import React from "react";
import { Container } from "@mui/material";
import { useIntl } from "react-intl";
import config from "../../../utils/config";
import Footer from "../../home/folds/Footer";

declare global {
  interface Window {
    AdobeDC: any;
  }
}

const StatutePage = () => {
  const { formatMessage } = useIntl();

  document.addEventListener("adobe_dc_view_sdk.ready", function () {
    var adobeDCView = new window.AdobeDC.View({
      clientId: config.ADOBE_VIEWER_ID,
      divId: "adobe-dc-view-statute",
    });
    adobeDCView.previewFile(
      {
        content: {
          location: {
            url: "https://storage.eljardinverde.org/docs/El-Jardin-Statute_EN.pdf",
          },
        },
        metaData: { fileName: "Statute - El Jardin Verde.pdf" },
      },
      {}
    );
  });

  return (
    <React.Fragment>
      <Helmet>
        <title>{formatMessage({ id: "seo.entityStatuteTitle" })}</title>
        <meta
          content={formatMessage({ id: "seo.entityStatuteTitle" })}
          property="og:title"
        ></meta>
        <meta
          content={formatMessage({ id: "seo.entityStatuteTitle" })}
          property="twitter:title"
        ></meta>
        <meta
          content={formatMessage({ id: "seo.entityStatuteDescription" })}
          property="og:description"
        ></meta>
        <meta
          content={formatMessage({ id: "seo.entityStatuteDescription" })}
          property="twitter:description"
        ></meta>
        <script src="https://acrobatservices.adobe.com/view-sdk/viewer.js"></script>
      </Helmet>
      <Container className="ease support-container">
        <div id="adobe-dc-view-statute"></div>
      </Container>
      <Footer />
    </React.Fragment>
  );
};

export default StatutePage;
