import { useState } from "react";
import { sanitize } from "../../utils/helpers";
import "./index.css";
import { Button } from "@mui/material";
import { useIntl } from "react-intl";

interface INewsletterForm {
  placeholderText: string;
  actionText: string;
  status: any;
  message: any;
  onValidated: any;
}

const NewsletterForm = ({
  placeholderText,
  actionText,
  status,
  message,
  onValidated,
}: INewsletterForm) => {
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const intl = useIntl();

  /**
   * Handle form submit.
   *
   * @return {{value}|*|boolean|null}
   */
  const handleFormSubmit = () => {
    setError("");

    if (!email) {
      setError(intl.formatMessage({ id: "mailchimp.error1" }));
      return null;
    }

    const isFormValidated = onValidated({ EMAIL: email });

    // On success return true
    return email && email.indexOf("@") > -1 && isFormValidated;
  };

  /**
   * Handle Input Key Event.
   *
   * @param event
   */
  const handleInputKeyEvent = (event: any) => {
    setError("");
    // Number 13 is the "Enter" key on the keyboard
    if (event.keyCode === 13) {
      // Cancel the default action, if needed
      event.preventDefault();
      // Trigger the button element with a click
      handleFormSubmit();
    }
  };

  /**
   * Extract message from string.
   *
   * @param {String} message
   * @return {null|*}
   */
  const getMessage = (message: any) => {
    if (!message) {
      return null;
    }
    const result = message?.split("-") ?? null;
    if ("0" !== result?.[0]?.trim()) {
      return sanitize(message);
    }
    const formattedMessage = result?.[1]?.trim() ?? null;
    return formattedMessage ? sanitize(formattedMessage) : null;
  };
  const translate = (message: string) => {
    let translatedMessage = null;

    if (message.includes("Thank you"))
      translatedMessage = intl.formatMessage({ id: "mailchimp.status1" });
    if (message.includes("must contain") || message.includes("invalid"))
      translatedMessage = intl.formatMessage({ id: "mailchimp.error1" });

    return translatedMessage;
  };

  return (
    <div className="newsletter-input-container">
      <div className="newsletter-input-fields">
        <div className="mc-field-group">
          <input
            onChange={(event) => setEmail(event?.target?.value ?? "")}
            type="email"
            placeholder={placeholderText}
            className="mc-mail-input"
            onKeyUp={(event) => handleInputKeyEvent(event)}
          />
        </div>
        <div className="mc-button-wrap">
          <Button className="mc-button-submit" onClick={handleFormSubmit}>
            {actionText.length ? (
              <span className="mc-submit-action-txt">{actionText}</span>
            ) : (
              ""
            )}

            {"sending" === status ? (
              <div className="loading-icon">
                <div className="dot-windmill"></div>
              </div>
            ) : (
              <img
                alt="get-notified"
                className="hero-cta-icon"
                src={require("../../assets/icons/get-notified.svg").default}
              />
            )}
          </Button>
        </div>
      </div>
      <div className="mc-message-container">
        {"error" === status || error.length ? (
          <div>{error || translate(getMessage(message))}</div>
        ) : null}
        {"success" === status && "error" !== status && !error.length && (
          <div className="mc-message-status">
            {translate(getMessage(message))}
          </div>
        )}
      </div>
    </div>
  );
};

export default NewsletterForm;
