import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";

interface StyledCardProps {
  rounded: "topLeft" | "bottomRight";
  bgcolor?: string;
  bgimageurl?: string;
}

export const StyledCard = styled(Box)<StyledCardProps>(
  ({ rounded, bgcolor, bgimageurl }) => ({
    position: "relative",
    height: "462px",
    borderRadius:
      rounded === "topLeft" ? "120px 0px 0px 0px" : "0px 0px 120px 0px",
    backgroundColor: bgcolor ?? "initial",
    backgroundImage: bgimageurl ? `url(${bgimageurl})` : "none",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    overflow: "hidden",
  })
);

interface StyledContentProps {
  content?: "top" | "bottom";
  text?: "light" | "dark";
  size?: "sm" | "lg";
}

export const StyledContent = styled(Box)<StyledContentProps>(
  ({ content, text, size }) => ({
    width: size === "sm" ? "326px" : "350px",
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    position: "absolute",
    left: "32px",
    [`${content ?? "top"}`]: "32px",
    color: text === "light" ? "#fff" : "1B1B1B",
  })
);

export const StyledTitle = styled(Typography)`
  font-family: Raleway;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px !important;
  letter-spacing: 0.96px;
`;

export const StyledText = styled(Typography)`
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.48px;
  padding-right: 10px;
`;

export const StyledOverlay = styled(Box)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.4) 0%,
    rgba(0, 0, 0, 0.7) 100%
  );
`;
