import en from "./en.json";
import es from "./es.json";
import { flattenMessages } from "../utils/helpers";

export enum LOCALES {
  ENGLISH = "en",
  SPANISH = "es",
}

export const messages: any = {
  [LOCALES.ENGLISH]: flattenMessages(en),
  [LOCALES.SPANISH]: flattenMessages(es),
};

export const locales = {
  [LOCALES.ENGLISH]: {
    icon: "🇺🇸",
    label: "English",
  },
  [LOCALES.SPANISH]: {
    icon: "🇪🇸",
    label: "Español",
  },
};
