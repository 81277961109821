import DOMPurify from "dompurify";

/***
 * Function to flatten the messages for react-intl v1>.
 */
export const flattenMessages = (nestedMessages: any, prefix = "") => {
  return Object.keys(nestedMessages).reduce((messages: any, key) => {
    let value = nestedMessages[key];
    let prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === "string") {
      messages[prefixedKey] = value;
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});
};

/**
 * Sanitize markup or text when used inside dangerouslysetInnerHTML
 *
 * @param {string} content Plain or html string.
 *
 * @return {string} Sanitized string
 */
export const sanitize = (content: any) => {
  return typeof window !== "undefined" ? DOMPurify.sanitize(content) : content;
};
