import { Container } from "@mui/material";
import { FormattedMessage } from "react-intl";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
//import PictureElement from "../../../../components/PictureElement";
import {
  StyledCard,
  StyledContent,
  StyledTitle,
  StyledText,
} from "../../../../components/StyledCard";
//import metamaskIcon from "../../../../assets/icons/metamask-icon.svg";
import howToIcon1 from "../../../../assets/icons3d/el-jardin-howto-step-1.svg";
import howToIcon2 from "../../../../assets/icons3d/el-jardin-howto-step-2.svg";
import howToIcon3 from "../../../../assets/icons3d/el-jardin-howto-step-3.svg";
import useIsMobile from "../../../../hooks/useIsMobile";

const HowItWorksMobile = ({
  compRef,
}: {
  compRef: React.RefObject<HTMLDivElement>;
}) => {
  const isMobile = useIsMobile();

  return (
    <div id="worksFold" className="works-fold works-mobile" ref={compRef}>
      <Container maxWidth="lg" disableGutters sx={{ px: 1 }}>
        <div className="slider-wrapper">
          <div className="fold-title-section">
            <div className="fold-title-text">
              <span className="fold-caption">Step by step</span>

              <h2 className="fold-title">
                <FormattedMessage id="discover.s4.title" />
              </h2>
            </div>
          </div>
        </div>

        <div className="slider-howto-wrapper">
          <OwlCarousel
            className="owl-theme howto-slider"
            margin={10}
            dots
            items={1}
          >
            <div className="item">
              <StyledCard
                rounded="bottomRight"
                bgcolor={"#404040"}
                height={"562px !important"}
              >
                <StyledContent
                  text="light"
                  width={isMobile ? "85% !important" : "300px !important"}
                >
                  <StyledTitle>
                    <span>1. </span>
                    <FormattedMessage id="discover.s4.activate.title" />
                  </StyledTitle>
                  <StyledText>
                    <FormattedMessage id="discover.s4.activate.text" />
                  </StyledText>

                  <img src={howToIcon1} className="slide-image" alt="howto-1" />
                </StyledContent>
              </StyledCard>
            </div>
            <div className="item">
              <StyledCard
                rounded="bottomRight"
                bgcolor={"#404040"}
                height={"562px !important"}
              >
                <StyledContent
                  text="light"
                  width={isMobile ? "85% !important" : "300px !important"}
                >
                  <StyledTitle>
                    <span>2. </span>
                    <FormattedMessage id="discover.s4.purchase.title" />
                  </StyledTitle>
                  <StyledText>
                    <FormattedMessage id="discover.s4.purchase.text" />
                  </StyledText>

                  <img src={howToIcon2} className="slide-image" alt="howto-2" />
                </StyledContent>
              </StyledCard>
            </div>

            <div className="item">
              <StyledCard
                rounded="bottomRight"
                bgcolor={"#404040"}
                height={"562px !important"}
              >
                <StyledContent
                  text="light"
                  width={isMobile ? "85% !important" : "300px !important"}
                >
                  <StyledTitle>
                    <span>3. </span>
                    <FormattedMessage id="discover.s4.access.title" />
                  </StyledTitle>
                  <StyledText>
                    <FormattedMessage id="discover.s4.access.text" />
                  </StyledText>

                  <img src={howToIcon3} className="slide-image" alt="howto-3" />
                </StyledContent>
              </StyledCard>
            </div>
          </OwlCarousel>
        </div>
      </Container>
    </div>
  );
};
export default HowItWorksMobile;
