import { Container } from "@mui/material";
import { useContext, useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./index.css";
import { FormattedMessage } from "react-intl";

import HeroButton from "./HeroButton";
import { MobileViewContext } from "../../../../mobileView/mobileViewProvider";
import BenefitsGrid from "./BenefitsGrid";

gsap.registerPlugin(ScrollTrigger);

const BenefitsFold = () => {
  const { isMobileView } = useContext(MobileViewContext);
  const comp = useRef<HTMLDivElement>(null);
  const tlBenefits = useRef(gsap.timeline({ paused: true }));

  const boxBenefits = useRef<HTMLDivElement>(null);
  const heroSection = useRef<HTMLDivElement>(null);

  const onClickHero = () => {
    if (heroSection && heroSection.current) {
      let elementPosition = heroSection.current.getBoundingClientRect().top;
      let offsetPosition =
        elementPosition + window.scrollY + (isMobileView ? 120 : 0);

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });

      //heroSection.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useLayoutEffect(() => {
    if (!isMobileView) {
      let ctx = gsap.context(() => {
        tlBenefits.current = gsap
          .timeline({
            scrollTrigger: {
              trigger: ".benefits-fold .fold-paragraph",
              start: "top bottom",
              end: "bottom top",
              scrub: 1,
              onEnter: ({ progress, direction, isActive }) => {
                if (boxBenefits.current) {
                  gsap.to(boxBenefits.current, {
                    width: 1180,
                    height: 770,
                    y: 80,
                    force3D: true,
                    border: "none",
                  });
                  gsap.to(boxBenefits.current.querySelector(".hero-button"), {
                    y: 140,
                    force3D: true,
                  });
                  gsap.to(".hero-button-arrow", {
                    y: 90,
                    force3D: true,
                    autoAlpha: 0,
                  });
                  gsap.to(
                    boxBenefits.current.querySelector(".hero-button-text"),
                    {
                      color: "#737373",
                    }
                  );
                }
              },
              onLeaveBack: ({ progress, direction, isActive }) => {
                gsap.to(boxBenefits.current, {
                  width: 210,
                  height: 48,
                  y: 0,
                  force3D: true,
                  borderRadius: 12,
                  border: "1px solid #1B1B1B",
                  boxShadow: "0px 24px 48px 0px rgba(131, 135, 210, 0)",
                });
                if (boxBenefits.current) {
                  gsap.to(boxBenefits.current.querySelector(".hero-button"), {
                    y: 0,
                    force3D: true,
                  });
                  gsap.to(".hero-button-arrow", {
                    y: 0,
                    force3D: true,
                    autoAlpha: 1,
                  });
                  gsap.to(
                    boxBenefits.current.querySelector(".hero-button-text"),
                    {
                      color: "#1B1B1B",
                    }
                  );
                }
              },
            },
          })
          .resume();
      }, comp); // <- IMPORTANT! Scopes selector text

      return () => ctx.revert(); 
    }
  }, [isMobileView]);

  return (
    <div id="parallaxFolds" className="parallax-folds" ref={comp}>
      <div id="benefitsFold" className="benefits-fold fold-light">
        <HeroButton
          heroRef={heroSection}
          benefitsRef={boxBenefits}
          onClick={onClickHero}
        />
        <Container
          className="home-wrapper"
          maxWidth="lg"
          disableGutters={true}
          sx={{ px: 1 }}
        >
          <div className="fold-title-section">
            <div className="fold-title-text">
              <h2 className="fold-title">
                <FormattedMessage id="discover.s3.title" />
              </h2>
              <p className="fold-paragraph"></p>
            </div>
          </div>
          <BenefitsGrid />
        </Container>
      </div>
    </div>
  );
};

export default BenefitsFold;
