import { Box, Button, Container, Link, styled } from "@mui/material";
import logo from "../../assets/logo/el-jardin-logo.svg";
import useIsMobile from "../../hooks/useIsMobile";
import menuIcon from "../../assets/icons/menu.svg";
import { useEffect, useState } from "react";
import { Menu } from "./Menu";
import { useIntl } from "react-intl";
import "./index.css";
import { useNavigate } from "react-router-dom";

const StyledHeader = styled(Box)`
  display: flex;
  padding: 16px 0;
  justify-content: space-between;
  align-items: center;
  background: #fff;
`;

const StyledMobileHeader = styled(Box)`
  margin-top: 32px;
  padding: 0 10px;
  background: #fff;
  position: relative;
  left: 0;
  top: 0;
  width: 94vw;
  height: 100vh;
`;

const Header = () => {
  const isMobile = useIsMobile();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const [openMobileMenu, setOpenMobileMenu] = useState(false);

  useEffect(() => {
    if (openMobileMenu) document.documentElement.style.overflow = "hidden";
    else document.documentElement.style.overflow = "auto";
  }, [openMobileMenu]);

  return (
    <Box
      sx={{
        background: "white",
        position: isMobile ? "fixed" : "relative",
        zIndex: 10,
        width: "100%",
      }}
    >
      <Container maxWidth="lg" disableGutters={true} sx={{ px: 1 }}>
        <StyledHeader>
          <Link
            onClick={() => navigate(formatMessage({ id: "links.home" }))}
            sx={{ cursor: "pointer" }}
          >
            <img src={logo} alt="logo" width={171} />
          </Link>

          {isMobile ? (
            <Box display={"flex"} alignItems={"center"} gap={"16px"}>
              <Button
                sx={{ p: 0, minWidth: "auto", justifyContent: "flex-end" }}
                onClick={() => setOpenMobileMenu((open) => !open)}
              >
                <img src={menuIcon} alt="menu" />
              </Button>
            </Box>
          ) : (
            <Menu />
          )}
        </StyledHeader>
      </Container>
      {isMobile && openMobileMenu && (
        <StyledMobileHeader>
          <Menu setOpenMobileMenu={setOpenMobileMenu} />
        </StyledMobileHeader>
      )}
    </Box>
  );
};

export default Header;
