import { Container, Grid } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { useState } from "react";
import "./index.css";
//import { useLang } from "../../../../lang/Provider";
import checkIcon from "../../../../assets/icons/check-green.svg";
import crossIcon from "../../../../assets/icons/cross-red.svg";
import cardEssential from "../../../../assets/card-essential.png";
import cardElite from "../../../../assets/card-elite.png";
import benefits from "../../../../lang/en.json";
import { StyledButton } from "../../../../components/StyledButton";
import { useNavigate } from "react-router-dom";

const OptionsFold = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const [dataEssential, setDataEssential] = useState(
    benefits.discover.s5.essentialCard as object
  );
  const [dataElite, setDataElite] = useState(benefits.discover.s5.eliteCard);

  const handleMembershipClick = (type: "elite" | "essential") => {
    localStorage.setItem("membershipSelected", type);
    navigate(
      formatMessage({
        id: "links.becomeMember",
      })
    );
  };

  return (
    <div id="optionsFold" className="options-fold">
      <div className="fold-title-section">
        <div className="fold-title-text">
          <span className="fold-caption">
            <FormattedMessage id="discover.s3.options.caption" />
          </span>
          <h2 className="fold-title">
            <FormattedMessage id="discover.s3.options.title" />
          </h2>
        </div>
      </div>
      <Container className="home-wrapper" maxWidth="lg" disableGutters={true}>
        <Grid container spacing={3} className="options-grid">
          <Grid item xs={12} md={6}>
            <div className="options-card card-essential-membership">
              <div className="options-card-title">
                <h3>Essential</h3>
                <p>Membership fee: €25</p>
              </div>

              <img src={cardEssential} alt="card-icon" width={"100%"} />

              <div className="options-card-text">
                {Object.keys(dataEssential).map((item: string, idx) => {
                  return (
                    <p key={idx}>
                      <img
                        src={idx > 2 ? crossIcon : checkIcon}
                        alt="option-icon"
                      />
                      {formatMessage({
                        id: "discover.s5.essentialCard.benefits" + idx,
                      })}
                    </p>
                  );
                })}
              </div>

              <StyledButton
                onClick={() => handleMembershipClick("essential")}
                sx={{
                  position: "absolute",
                  top: "auto",
                  bottom: "40px",
                  width: "calc(100% - 162px)",
                }}
              >
                Get Essential membeship
              </StyledButton>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="options-card card-elite-membership">
              <div className="options-card-title">
                <h3>VIP</h3>
                <p>Membership fee: €50</p>
              </div>

              <img src={cardElite} alt="card-icon" width={"100%"} />

              <div className="options-card-text">
                {Object.keys(dataElite).map((item: string, idx) => {
                  return (
                    <p key={idx}>
                      <img src={checkIcon} alt="option-icon" />
                      {formatMessage({
                        id: "discover.s5.eliteCard.benefits" + idx,
                      })}
                    </p>
                  );
                })}
              </div>

              <StyledButton
                variation="secondary"
                onClick={() => handleMembershipClick("elite")}
                sx={{
                  position: "absolute",
                  top: "auto",
                  bottom: "40px",
                  width: "calc(100% - 162px)",
                  color: "#1B1B1B",
                }}
              >
                Get VIP membership
              </StyledButton>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default OptionsFold;
