import { Container, Button } from "@mui/material";
import { FormattedMessage } from "react-intl";
import arrow from "../../../../assets/icons/button-arrow.svg";

const HeroButton = ({
  heroRef,
  benefitsRef,
  onClick,
}: {
  heroRef: React.RefObject<HTMLDivElement>;
  benefitsRef: React.RefObject<HTMLDivElement>;
  onClick: () => void;
}) => {
  return (
    <Container className="home-wrapper" maxWidth="lg" disableGutters={true}>
      <div id="heroButtonSection" className="hero-button-section" ref={heroRef}>
        <div className="hero-button-container" ref={benefitsRef}>
          <Button className="hero-button">
            <span onClick={onClick} className="hero-button-text">
              <FormattedMessage id="discover.s1.buttonText" />
            </span>
          </Button>
        </div>

        <img
          src={arrow}
          className="hero-button-arrow"
          alt="hero-button-arrow"
        />
      </div>
    </Container>
  );
};

export default HeroButton;
