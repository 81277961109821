import MailchimpSubscribe from "react-mailchimp-subscribe";
import NewsletterForm from "../../../../components/NewsletterForm";

interface INewsletterSubscribe {
  placeholderText: string;
  actionText: string;
}

const NewsletterSubscribe = ({
  placeholderText,
  actionText,
}: INewsletterSubscribe) => {
  const mailchimpPublicUrl = ""; 

  return (
    <MailchimpSubscribe
      url={mailchimpPublicUrl}
      render={(props: any) => {
        const { subscribe, status, message } = props || {};
        return (
          <NewsletterForm
            placeholderText={placeholderText}
            actionText={actionText}
            status={status}
            message={message}
            onValidated={(formData: any) => subscribe(formData)}
          />
        );
      }}
    />
  );
};

export default NewsletterSubscribe;
