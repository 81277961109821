import { Button, styled } from "@mui/material";

interface StyledButtonProps {
  variation?: "secondary";
}

export const StyledButton = styled(Button)<StyledButtonProps>(
  ({ theme, variation }) => ({
    height: 40,
    width: 141,
    backgroundColor:
      variation === "secondary" ? "#F5F5F5" : theme.palette.secondary.main,
    "&:hover": {
      background:
        variation === "secondary" ? "#F5F5F5" : theme.palette.secondary.main,
      boxShadow: "none",
    },
    "&.Mui-disabled": {
      color: "#F5F5F5",
    },
    padding: "12px",
    borderRadius: "16px 0px 0px 0px",
    fontFamily: "Raleway",
    fontSize: "14px",
    color: variation === "secondary" ? "#525252" : "#fff",
    fontWeight: 600,
    textTransform: "capitalize",
    boxShadow: "none",
    top: "5px"
  })
);
