import { ThemeOptions } from "@mui/material/styles";

const theme: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 992,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    mode: "light",
    primary: {
      main: "#22C55E",
      contrastText: "#fff",
    },
    secondary: {
      main: "#7C3AED",
      contrastText: "#fff",
    },
    background: {
      default: "lightgray",
    },
    warning: {
      main: "#FFCF25",
      contrastText: "#fff",
    },
    error: {
      main: "#E60F41",
    },
  },
  typography: {
    fontFamily: "Raleway",
  },
  spacing: 10,
};

export default theme;
