import "./index.css";

import { useIntl } from "react-intl";
import { Helmet } from "react-helmet";
import React from "react";
import { useHashScroll } from "../../hooks/useHashScroll";
import AppFold from "./folds/AppFold";
import BenefitsFold from "./folds/BenefitsFold";
import Footer from "./folds/Footer";
import HeroFold from "./folds/HeroFold";
import HowItWorksFold from "./folds/HowItWorksFold";
import SubscribeFold from "./folds/SubscribeFold";
import OptionsFold from "./folds/OptionsFold";

function HomePage() {
  const { formatMessage } = useIntl();

  useHashScroll();

  return (
    <React.Fragment>
      <Helmet>
        <title>{formatMessage({ id: "seo.homeTitle" })}</title>
        <meta
          content={formatMessage({ id: "seo.homeTitle" })}
          property="og:title"
        ></meta>
        <meta
          content={formatMessage({ id: "seo.homeTitle" })}
          property="twitter:title"
        ></meta>
        <meta
          content={formatMessage({ id: "seo.homeDescription" })}
          property="og:description"
        ></meta>
        <meta
          content={formatMessage({ id: "seo.homeDescription" })}
          property="twitter:description"
        ></meta>
      </Helmet>

      <div className="home-container">
        <HeroFold />
        <BenefitsFold />
        <OptionsFold />
        <HowItWorksFold />

        <AppFold />
        <SubscribeFold />
        <Footer />
      </div>
    </React.Fragment>
  );
}

export default HomePage;
