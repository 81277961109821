import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
  createSelector,
  Selector,
} from "@reduxjs/toolkit";
import thunk from "redux-thunk";

import applicationSlice from "./application/application.slice";

import { IApplicationState } from "./application/application.slice";

export const rootReducer = combineReducers({
  application: applicationSlice,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk],
});

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const selectRootStore = (state: RootState) => state;

export const selectAppState: Selector<RootState, IApplicationState> =
  createSelector(selectRootStore, (state: RootState) => state.application);
