import { Container } from "@mui/system";
import { FormattedMessage, useIntl } from "react-intl";
import "./index.css";
import NewsletterSubscribe from "../NewsletterSubscribe";
import useIsMobile from "../../../../hooks/useIsMobile";

const SubscribeForm = () => {
  const intl = useIntl();

  return (
    <NewsletterSubscribe
      placeholderText={intl.formatMessage({
        id: "footer.subscribePlaceholder",
      })}
      actionText={intl.formatMessage({ id: "footer.subscribeButton" })}
    />
  );
};

const SubscribeFold = () => {
  const isMobile = useIsMobile();

  return (
    <Container
      maxWidth="lg"
      className="subscribe-container"
      disableGutters
      sx={{ px: 1 }}
    >
      <div
        id="subscribeFold"
        className="subscribe-fold"
        style={{ flexDirection: isMobile ? "column" : "row" }}
      >
        <h2 className="subscribe-title">
          <FormattedMessage id="footer.title" />
        </h2>
        <SubscribeForm />
      </div>
    </Container>
  );
};

export default SubscribeFold;
