import { Container, Grid, Button } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, useNavigate } from "react-router-dom";
import NewsletterSubscribe from "../NewsletterSubscribe";
//import { useContext } from "react";
import cardImage from "../../../../assets/el-jardin-card.svg";
import "./index.css";

const HeroFold = () => {
  //const navigate = useNavigate();
  const { formatMessage } = useIntl();

  return (
    <div className="hero-fold">
      <Container
        className="home-wrapper"
        maxWidth="lg"
        disableGutters={true}
        sx={{ px: 1 }}
      >
        <div className="hero-main-section">
          <Grid container spacing={2} className="hero-grid">
            <Grid item xs={12} md={7}>
              <div className="hero-content">
                <h1 className="hero-title">
                  <FormattedMessage id="discover.s1.title" />
                </h1>
                <div className="hero-title-text">
                  <FormattedMessage id="discover.s1.text" />
                </div>
                <div className="hero-actions">
                  <NewsletterSubscribe
                    placeholderText={formatMessage({
                      id: "discover.s1.cta1",
                    })}
                    actionText=""
                  ></NewsletterSubscribe>

                  <Button
                    className="hero-cta-2"
                    component="a"
                    href="https://auth.eljardinverde.org"
                  >
                    <FormattedMessage id="discover.s1.cta2" />
                  </Button>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={5}>
              <div className="hero-member-card">
                <img src={cardImage} alt="card" />

                <div className="hero-card-overlay"></div>
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
};
export default HeroFold;
