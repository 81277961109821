import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import AppRouter from "./pages";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel";
import config from "./utils/config";
import { Crisp } from "crisp-sdk-web";

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });

    let path = window.location.pathname.split("/").slice(1);
    let clearedPath = path.filter((el) => el !== "");
    let label = clearedPath.join("-").replace("ro-", "").replace("ro", "");

    if (label.length) document.body.classList.add("page-" + label);
    else document.body.classList.add("page-home");

    return () => {
      if (label.length) document.body.classList.remove("page-" + label);
      else document.body.classList.remove("page-home");
    };
  }, [pathname]);

  useEffect(() => {
    if (config.CRISP_ID) {
      Crisp.configure(config.CRISP_ID as string);
      // Crisp.chat.hide();
      Crisp.chat.onChatClosed(() => {
        // Crisp.chat.hide();
      });
    }
  }, []);

  return <AppRouter />;
}

export default App;
