import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import theme from "./brand/theme";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { MobileViewProvider } from "./mobileView/mobileViewProvider";
import LangProvider from "./lang/Provider";
import "./App.css";
import { Provider } from "react-redux";
import { PersistGate } from "reduxjs-toolkit-persist/integration/react";
import { store, persistor } from "./redux/store";

declare global {
  interface Window {
    dataLayer: any;
  }
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.Fragment>
    <BrowserRouter>
      <LangProvider>
        <ThemeProvider theme={createTheme(theme)}>
          <MobileViewProvider>
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <App />
              </PersistGate>
            </Provider>
          </MobileViewProvider>
        </ThemeProvider>
      </LangProvider>
    </BrowserRouter>
  </React.Fragment>
);
