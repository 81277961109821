import styled from "@emotion/styled";
import { Box, Divider, Link } from "@mui/material";
import { useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { StyledButton } from "../StyledButton";
import { LanguageSwitch } from "./LanguageSwitch";
import { useLocation, useNavigate } from "react-router-dom";
import useIsMobile from "../../hooks/useIsMobile";
import { Link as RouterLink } from "react-router-dom";

const StyledMenu = styled(Box)`
  display: flex;
  gap: 32px;
`;

const StyledMenuItem = styled(Link)`
  color: #1b1b1b;
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.42px;
  text-decoration: none;
  cursor: pointer;
  position: relative;
`;

const StyledMobileLink = styled.a`
  color: #7c3aed;
  font-size: 14px;
  line-height: 18px;
  font-weight: 800;
  text-decoration: none;
`;

export const Menu = ({
  setOpenMobileMenu,
}: {
  setOpenMobileMenu?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  // const { pathname } = useLocation();

  const handleMenuItemClick = useCallback(
    (elId: string) => {
      let el = document.getElementById(elId);

      if (!el) {
        navigate(formatMessage({ id: "links.home" }));
        setTimeout(() => {
          el = document.getElementById(elId);
          if (el) el.scrollIntoView({ behavior: "smooth" });
        });
      } else if (el) el.scrollIntoView({ behavior: "smooth" });

      setOpenMobileMenu?.(false);
    },
    [formatMessage, navigate, setOpenMobileMenu]
  );

  const menuItems = (
    <>
      <StyledMenuItem
        href="#benefits"
        onClick={() => handleMenuItemClick("benefitsFold")}
        fontSize={isMobile ? "16px" : "14px"}
      >
        <FormattedMessage id="menu.sections.benefits" />
      </StyledMenuItem>
      <StyledMenuItem
        href="#how-it-works"
        onClick={() => handleMenuItemClick("worksFold")}
        fontSize={isMobile ? "16px" : "14px"}
      >
        <FormattedMessage id="menu.sections.works" />
      </StyledMenuItem>
      <StyledMenuItem
        href="#el-jardin-app"
        onClick={() => handleMenuItemClick("appFold")}
        fontSize={isMobile ? "16px" : "14px"}
      >
        <FormattedMessage id="menu.sections.app" />
      </StyledMenuItem>
    </>
  );

  if (isMobile)
    return (
      <Box display={"flex"} flexDirection={"column"} gap={"16px"}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <StyledMobileLink
            href="https://auth.eljardinverde.org"
            onClick={() => setOpenMobileMenu?.(false)}
          >
            Get membership
          </StyledMobileLink>

          <LanguageSwitch setOpenMobileMenu={setOpenMobileMenu} />
        </Box>
        <Divider
          flexItem
          orientation="vertical"
          sx={{ borderColor: "#A3A3A3", borderBottomWidth: "0.1px" }}
        />
        <StyledMenu
          flexDirection={"column"}
          mt={"32px"}
          className="mobile-menu-items"
        >
          {menuItems}
        </StyledMenu>
      </Box>
    );

  return (
    <Box display={"flex"} gap={"34px"}>
      <StyledMenu alignItems={"center"}>{menuItems}</StyledMenu>

      <StyledButton href="https://auth.eljardinverde.org">
        {formatMessage({ id: "discover.s1.cta2" })}
      </StyledButton>

      <LanguageSwitch />
    </Box>
  );
};
