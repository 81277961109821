import "./index.css";
import { useContext, useRef } from "react";

import { MobileViewContext } from "../../../../mobileView/mobileViewProvider";
import HowItWorksMobile from "./HowItWorksMobile";
import HowItWorksDesktop from "./HowItWorksDesktop";

const HowItWorksFold = () => {
  const { isMobileView } = useContext(MobileViewContext);
  const comp = useRef<HTMLDivElement>(null);

  if (isMobileView) return <HowItWorksMobile compRef={comp} />;

  return <HowItWorksDesktop compRef={comp} />;
};

export default HowItWorksFold;
