import { useState, useLayoutEffect } from "react";
import { FormattedMessage } from "react-intl";
//import PictureElement from "../../../../components/PictureElement";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import howToIcon1 from "../../../../assets/icons3d/el-jardin-howto-step-1.svg";
import howToIcon2 from "../../../../assets/icons3d/el-jardin-howto-step-2.svg";
import howToIcon3 from "../../../../assets/icons3d/el-jardin-howto-step-3.svg";

gsap.registerPlugin(ScrollTrigger);

const HowItWorksDesktop = ({
  compRef,
}: {
  compRef: React.RefObject<HTMLDivElement>;
}) => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  useLayoutEffect(() => {
    // create our context. This function is invoked immediately and all GSAP animations and ScrollTriggers created during the execution of this function get recorded so we can revert() them later (cleanup)
    let ctx = gsap.context(() => {
      let sections = gsap.utils.toArray(".slide");
      let slide3 = document.getElementById("slide3");
      let currentSection = sections[0];

      function setSection(newSection: any) {
        if (newSection !== currentSection && currentSection) {
          gsap.to(currentSection, { scale: 1, autoAlpha: 0 });
          gsap.to(newSection, { scale: 1, autoAlpha: 1 });
          currentSection = newSection;
        }
      }

      gsap.to(sections, {
        //xPercent: -100 * (sections.length - 1),
        ease: "none",
        scrollTrigger: {
          trigger: ".slider-wrapper",
          pin: true,
          scrub: 1,
          end: "+=" + window.innerHeight * 2 * sections.length,
          onEnter: ({ progress, direction, isActive }) => {
            setIsFullscreen(true);
            gsap.to(".works-fold-overlay", {
              width: "100vw",
              height: "100vh",
              y: -270,
              x: "-50%",
              force3D: true,
              left: "50vw",
              borderRadius: "300px 0px 0px 0px",
            });
            gsap.to(".slider-wrapper .fold-title-section", {
              y: 60,
              force3D: true,
            });
            gsap.to(".fold-caption", {
              opacity: 1,
              ease: "ease-in-out",
            });
            gsap.to(".works-fold .fold-title", {
              color: "#fff",
              ease: "ease-in-out",
            });
            if (slide3)
              gsap.to(slide3, {
                y: "-20%",
                force3D: true,
              });
          },
          onLeaveBack: ({ progress, direction, isActive }) => {
            setIsFullscreen(false);
            gsap.to(".works-fold-overlay", {
              width: 1180,
              height: "100vw",
              y: 0,
              force3D: true,
              ease: "none",
              borderRadius: "300px 0px 0px 0px",
            });
            gsap.to(".slider-wrapper .fold-title-section", {
              y: 0,
              force3D: true,
            });
            gsap.to(".fold-caption", {
              opacity: 0,
              ease: "ease-in-out",
            });
            gsap.to(".works-fold .fold-title", {
              color: "#101120",
              ease: "ease-in-out",
            });
            if (slide3)
              gsap.to(slide3, {
                y: "0",
                force3D: true,
              });
          },
          onUpdate: (self) => {
            let updateTrigger = false;

            for (let i = 0, l = sections.length; i < l; i++) {
              if (
                (1 / l) * i < self.progress &&
                self.progress <= (1 / l) * (i + 1) &&
                !updateTrigger
              ) {
                updateTrigger = !updateTrigger;
                setSection(sections[i]);

                if (
                  i === l - 1 &&
                  self.direction === 1 &&
                  self.progress >= 0.89
                ) {
                  gsap.to("#worksFold .fold-title-section", {
                    y: 0 - 90,
                    force3D: true,
                    opacity: 0,
                  });
                  gsap.to("#slide5 .slide-image", {
                    autoAlpha: 0,
                    force3D: true,
                    duration: 0.4,
                  });
                  gsap.to("#slide5 .slide-container", {
                    opacity: 0,
                    duration: 0.3,
                    transform: "translate3D(-50%,-80%,0)",
                  });
                } else if (
                  i === l - 1 &&
                  self.direction === -1 &&
                  self.progress < 0.9
                ) {
                  gsap.to("#worksFold .fold-title-section", {
                    y: 60,
                    force3D: true,
                    opacity: 1,
                  });
                  gsap.to("#slide5 .slide-image", {
                    autoAlpha: 1,
                    force3D: true,
                  });
                  gsap.to("#slide5 .slide-container", {
                    opacity: 1,
                    duration: 0.4,
                    transform: "translate3D(-50%,-50%,0)",
                  });
                }
              }
            }
          },
        },
      });
    }, compRef); // <- IMPORTANT! Scopes selector text

    return () => ctx.revert(); // cleanup
  }, [compRef]);

  return (
    <div
      id="worksFold"
      className={`works-fold ${isFullscreen ? "works-fullscreen" : ""}`}
      ref={compRef}
    >
      <div>
        <div className="slider-wrapper">
          <div className="works-fold-overlay"></div>

          <div className="fold-title-section">
            <div className="fold-title-text">
              <span className="fold-caption">Step by step</span>
              <h2 className="fold-title">
                <FormattedMessage id="discover.s4.title" />
              </h2>
            </div>
          </div>
          <div id="slide3" className="slide">
            <div className="slide-container s-container-size-2">
              <h3 className="slide-title">
                <span className="slide-title-no">1. </span>
                <FormattedMessage id="discover.s4.activate.title" />
              </h3>
              <p className="slide-text">
                <FormattedMessage id="discover.s4.activate.text" />
              </p>

              <img src={howToIcon1} className="slide-image" alt="howto-1" />

            </div>
          </div>
          <div id="slide4" className="slide">
            <div className="slide-container s-container-size-2">
              <div className="slide-wrapper">
                <h3 className="slide-title">
                  <span className="slide-title-no">2. </span>
                  <FormattedMessage id="discover.s4.purchase.title" />
                </h3>
                <p className="slide-text">
                  <FormattedMessage id="discover.s4.purchase.text" />
                </p>

                <img src={howToIcon2} className="slide-image" alt="howto-2" />
              </div>
            </div>
          </div>
          <div id="slide5" className="slide">
            <div className="slide-container s-container-size-2">
              <h3 className="slide-title">
                <span className="slide-title-no">3. </span>
                <FormattedMessage id="discover.s4.access.title" />
              </h3>
              <p className="slide-text">
                <FormattedMessage id="discover.s4.access.text" />
              </p>

              <img src={howToIcon3} className="slide-image" alt="howto-3" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HowItWorksDesktop;
